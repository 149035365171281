import { useRef, useEffect } from 'react';
import './Letter.css';
import MenuIcons from './MenuIcons';
import ActualStamps from './ActualStamps';
import SmallMenuIcons from './SmallMenuIcons';

function Letter() {

    const inputEl = useRef(null);

    const setStampIcon = function (stampIcon) {

        const actualStamp = ActualStamps[stampIcon];

        inputEl.current.innerHTML += `<img src=${actualStamp.img} />`;
    };

    useEffect(() => {

        inputEl.current.innerHTML = "Dear Santa,";
    });

    return (
        <div className="Letter">
            <SmallMenuIcons setStampIcon={setStampIcon} />
            <div
                ref={inputEl}
                className="Letter-textarea"
                contentEditable={true}
            >
            </div>
            <MenuIcons setStampIcon={setStampIcon} />
        </div>
    );
}

export default Letter;
