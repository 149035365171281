import './Stamps.css';
import ActualStamps from './ActualStamps';

function Stamps(props) {

    if (!props.show) {
        return <></>;
    }

    const addStocking = function (icon) { props.setStamp(icon); };

    return (
        <>
            <div className="Stamps">
                
                {Object.keys(ActualStamps).map((key) => {
                    return (
                        <button key={key} onClick={addStocking.bind(this, key)} className="Stamp-button">
                            <img src={ActualStamps[key].img} alt={ActualStamps[key].alt} />
                        </button>
                    );
                })}

                <br />
                <button className="Stamps-cancel" onClick={props.cancel}>CANCEL</button>
            </div>
            <div className="Stamps-background" />
        </>
    );
}

export default Stamps;
