import './Footer.css';

function Footer() {

  return (
    <footer className="Footer">
        © {new Date().getFullYear()} 
        <a href="https://donquixote.fun" target="_blank" rel="noopener noreferrer" className="Footer-link"> DonQuixote LLC</a>
    </footer>
  );
}

export default Footer;
