
import './Snow.css';

function randomIntFromInterval(min, max) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min);
}

function Snow(props) {

    const snowFlakes = [];

    for (let index = 0; index < 200; index++) {
        
        const fallDelay = Math.random() * 3;
        const fallDuration = randomIntFromInterval(10, 20);
        const scale = Math.random();
        const translateX = Math.random() * window.screen.width;
        const flake = {
            scale: scale,
            opacity: Math.random(),
            animation: `fall-${index} ${fallDuration}s ${fallDelay}s linear infinite`,
            translateX: translateX
        };
        snowFlakes.push(flake);
    }

    return (
        <div className="Snow">
            {snowFlakes.map((flake, index) => {
                const randomYoyo = randomIntFromInterval(20, 200);
                const randomYoyoTime = (Math.random() * 50).toFixed(0);
                return <style key={`style-${index}`}>{`@keyframes fall-${index} {
                            0% {
                                transform: translateX(${flake.translateX}px) translateY(0vh) scale(${flake.scale});
                            }
                            ${randomYoyoTime}% {
                                transform: translateX(${flake.translateX + (randomYoyo * 0.01 * randomYoyoTime)}px) translateY(${randomYoyoTime}vh) scale(${flake.scale});
                            }
                            100% {
                                transform: translateX(${flake.translateX}px) translateY(100vh) scale(${flake.scale});
                            }
                        }`}</style>;
            })}
            {snowFlakes.map((flake, index) => {
                return <span key={`flake-${index}`} className="Snow-flake" style={{ opacity: flake.opacity, transform: flake.transform, animation: flake.animation }}></span>;
            })}
        </div>
    );
}

export default Snow;

// .snow {
//     $total: 200;
//     position: absolute;
//     width: 10px;
//     height: 10px;
//     background: white;
//     border-radius: 50%;
  
//     @for $i from 1 through $total {
//       $random-x: random(1000000) * 0.0001vw;
//       $random-offset: random_range(-100000, 100000) * 0.0001vw;
//       $random-x-end: $random-x + $random-offset;
//       $random-x-end-yoyo: $random-x + ($random-offset / 2);
//       $random-yoyo-time: random_range(30000, 80000) / 100000;
//       $random-yoyo-y: $random-yoyo-time * 100vh;
//       $random-scale: random(10000) * 0.0001;
//       $fall-duration: random_range(10, 30) * 1s;
//       $fall-delay: random(30) * -1s;
  
//       &:nth-child(#{$i}) {
//         opacity: random(10000) * 0.0001;
//         transform: translate($random-x, -10px) scale($random-scale);
//         animation: fall-#{$i} $fall-duration $fall-delay linear infinite;
//       }
  
//       @keyframes fall-#{$i} {
//         #{percentage($random-yoyo-time)} {
//           transform: translate($random-x-end, $random-yoyo-y) scale($random-scale);
//         }
  
//         to {
//           transform: translate($random-x-end-yoyo, 100vh) scale($random-scale);
//         }
//       }
//     }
//   }