import { useState } from 'react';
import './SmallMenuIcons.css';
import stamp from './images/stamp.png';
import printer from './images/printer.png';
import Stamps from './Stamps';

function SmallMenuIcons(props) {

    const [showStamps, setShowStamps] = useState(false);

    const print = function () {
        window.print();
    };

    const toggleShowStamp = function () {
        setShowStamps(!showStamps);
    };

    const setStamp = function (stampIcon) {
        props.setStampIcon(stampIcon);
        setShowStamps(false);
    };

    const cancel = function () {
        setShowStamps(false);
    };

    return (
        <div className="SmallMenuIcons">
            <button className="SmallMenuIcons-button" title="Add some stamps to your letter" onClick={toggleShowStamp}>
                <img src={stamp} alt="" className="SmallMenuIcons-img" />
                <br />
                STAMPS
            </button>
            <Stamps show={showStamps} setStamp={setStamp} cancel={cancel} />
            <button className="SmallMenuIcons-button" title="Print for Santa" onClick={print}>
                <img src={printer} alt="" className="SmallMenuIcons-img" />
                <br />
                PRINT
            </button>
        </div>
    );
}

export default SmallMenuIcons;
