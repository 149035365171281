import App from './App';
import './Container.css';
import Snow from './Snow';

function Container() {

    return (
        <div className="Container">
            <Snow />
            <div className="Container-app">
                <App />
            </div>
        </div>
    );
}

export default Container;
