import stocking from './images/stocking.png';
import sled from './images/sled.png';
import gingerbreadman from './images/gingerbreadman.png';
import tree from './images/tree.png';
import present from './images/present.png';
import stocking2 from './images/stocking2.png';
import snowflake from './images/snowflake.png';
import bow from './images/bow.png';
import ornament from './images/ornament.png';


const stampIconImages = {
    stocking: {
        alt: "Stocking",
        img: stocking
    },
    sled: {
        alt: "Sled",
        img: sled
    },
    gingerbreadman: {
        alt: "Gingerbread Man",
        img: gingerbreadman
    },
    tree: {
        alt: "Christmas Tree",
        img: tree
    },
    present: {
        alt: "Present",
        img: present
    },
    stocking2: {
        alt: "Stocking",
        img: stocking2
    },
    snowflake: {
        alt: "Snowflake",
        img: snowflake
    },
    bow: {
        alt: "Christmas Bow",
        img: bow
    },
    ornament: {
        alt: "Christmas Ornament",
        img: ornament
    }
};

export default stampIconImages;
