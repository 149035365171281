import { useEffect } from 'react';
import logo from './images/logo.png';
import './App.css';
import Letter from './Letter';
import Footer from './Footer';

let loadedMusicAready = false;

function App() {
  useEffect(() => {
    document.addEventListener("click", function () {

      if (!loadedMusicAready) {

        let audio = new Audio('silentnight.mp3');
        audio.volume = 0.1;
        audio.play();
      }
      loadedMusicAready = true;
    });
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
      </header>
      <Letter />
      <Footer />
    </div>
  );
}

export default App;
